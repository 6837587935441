import React, { Fragment, useImperativeHandle, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { CatalogueListItem } from '@/components/CatalogueListItem/CatalogueListItem';
import { getAdPagePath, sleep } from '@/utils/helpers';
import { Drawer } from '@/components/Drawer/Drawer';
import {
  DrawerItem,
  DrawerItems,
  DrawerItemsName,
} from '@/components/Drawer/Drawer.styles';
import { useBodyOverflow } from '@/hooks/useBodyOverflow';
import { AngleBackIcon } from '@/components/UI/Icons/Icons';
import { ROUTES } from '@/constants/routes';
import { BaseCategory, PropertyOption } from '@/api/types/categories.types';
import { Checkbox } from '@/components/UI/Checkbox/Checkbox';
import { findAndGroupCategoriesByParentName } from '@/components/CatalogueDrawer/CatalogueDrawer.helpers';
import { DRAWER_TRANSITION_TIME_MS } from '@/components/Drawer/Drawer.constants';
import { ALL_SHOPS_ID } from '@/components/CatalogueScrollGrid/CatalogueScrollGrid.constants';
import { useGoToPageWithSaveScrollPosition } from '@/components/ScrollSaver/ScrollSaver.hooks';
import { useCustomPush } from '@/components/CustomLink/CustomLink.hooks';

interface CatalogueDrawerProps {
  isOpen: boolean;
  close: () => void;
  onSelect?: (category: BaseCategory, subcategory?: BaseCategory) => void;
  hasShopsLink?: boolean;
  hasSubcategories?: boolean;
  categories?: BaseCategory[];
  hasSwitchCheckbox?: boolean;
}

export interface CatalogueDrawerRef {
  reset: () => void;
}

export const CatalogueDrawer = React.forwardRef<
  CatalogueDrawerRef,
  CatalogueDrawerProps
>(
  (
    {
      isOpen,
      close,
      onSelect,
      hasShopsLink = true,
      hasSubcategories = true,
      categories,
      hasSwitchCheckbox = true,
    },
    catalogueDrawerRef
  ) => {
    useBodyOverflow(isOpen);

    const { t } = useTranslation();
    const router = useRouter();
    const customPush = useCustomPush();
    const goToPageWithSaveScrollPosition = useGoToPageWithSaveScrollPosition();

    const [isSubcategoryOpen, setIsSubcategoryOpen] = useState(false);
    const [selectedCategory, setSelectedCategory] =
      useState<null | BaseCategory>(null);
    const [subcategoryId, setSubcategoryId] = useState('');
    const [query, setQuery] = useState('');

    const filteredSubcategories = useMemo(
      () => Array.from(findAndGroupCategoriesByParentName(query, categories)),
      [query]
    );

    useImperativeHandle(
      catalogueDrawerRef,
      () => ({
        reset: () => {
          setSubcategoryId('');
        },
      }),
      []
    );

    function openSubDrawer(category: BaseCategory) {
      setSelectedCategory(category);
      setIsSubcategoryOpen(true);
    }

    async function closeSubDrawer() {
      setIsSubcategoryOpen(false);
      await sleep(500);
      setSelectedCategory(null);
    }

    async function onCategoryClick(category: BaseCategory) {
      if (hasSubcategories) {
        openSubDrawer(category);
      } else {
        onSelect?.(category);
        close();
        await closeSubDrawer();
      }
    }

    async function onSubcategoryClick(
      subcategory: BaseCategory,
      parentCategory?: BaseCategory
    ) {
      const actualParentCategory = parentCategory || selectedCategory;

      if (!actualParentCategory || !onSelect) return;

      onSelect(actualParentCategory, subcategory);

      close();
      setSubcategoryId(subcategory.id);
      await closeSubDrawer();
      await sleep(DRAWER_TRANSITION_TIME_MS);
      setQuery('');
    }

    function isSelectedCategory(path?: string) {
      const currentPath = getAdPagePath(router.query.slug);

      return `/${path}` === currentPath;
    }

    function renderCommonCheckbox(
      item: BaseCategory | PropertyOption,
      path: string
    ) {
      return (
        <Checkbox
          onChangeHook={() => goToPageWithSaveScrollPosition(path, true)}
          key={item.id}
          label={item.name}
          appearance="radio"
          size="sm"
          hasSwitch={hasSwitchCheckbox}
        />
      );
    }

    function renderCategoryCheckbox(item: BaseCategory) {
      const path = `/${item.path}`;
      return onSelect ? (
        <Checkbox
          onChangeHook={() => onSubcategoryClick(item)}
          key={item.id}
          value={
            subcategoryId ? item.id === subcategoryId : isSelectedCategory(path)
          }
          label={item.name}
          appearance="radio"
          size="sm"
          hasSwitch={hasSwitchCheckbox}
        />
      ) : (
        renderCommonCheckbox(item, path)
      );
    }

    function renderPropertyCheckbox(item: PropertyOption) {
      const path = `/${selectedCategory?.path}?p%5B${item.propertyId}%5D=${item.id}`;
      return renderCommonCheckbox(item, path);
    }

    return (
      <Drawer
        dataTestId="catalogue-drawer"
        isOpen={isOpen}
        close={close}
        title={t('catalogue.catalogue')}
        // onSearch={setQuery} // uncomment when search will be needed
        isInPortal
      >
        {query ? (
          filteredSubcategories.map(([parentName, subcategories]) => {
            return (
              <Fragment key={parentName}>
                <DrawerItemsName>{parentName}</DrawerItemsName>
                <DrawerItems>
                  {subcategories.map(subcategory => (
                    <Checkbox
                      onChangeHook={() =>
                        onSelect
                          ? onSubcategoryClick(subcategory, subcategory.parent)
                          : customPush(`/${subcategory.path}`)
                      }
                      key={subcategory.id}
                      value={
                        subcategoryId
                          ? subcategory.id === subcategoryId
                          : isSelectedCategory(subcategory.path)
                      }
                      label={subcategory.name}
                      appearance="radio"
                      size="sm"
                      hasSwitch={hasSwitchCheckbox}
                    />
                  ))}
                </DrawerItems>
              </Fragment>
            );
          })
        ) : (
          <>
            <DrawerItems>
              {categories?.map((item, i) => {
                return (
                  <DrawerItem
                    key={item.id}
                    as="div"
                    $isFirst={!i}
                    onClick={() => onCategoryClick(item)}
                  >
                    <CatalogueListItem item={item} />
                  </DrawerItem>
                );
              })}

              {hasShopsLink && (
                <DrawerItem href={ROUTES.shops} as={Link}>
                  <CatalogueListItem
                    data-stat="catalog-category-shop"
                    item={
                      {
                        id: ALL_SHOPS_ID,
                        icon: 'shop.png',
                        name: t('catalogue.stores'),
                      } as BaseCategory
                    }
                  />
                </DrawerItem>
              )}
            </DrawerItems>

            <Drawer
              dataTestId="catalogue-subdrawer"
              isOpen={isSubcategoryOpen}
              title={selectedCategory?.name}
              left={{ icon: <AngleBackIcon />, onClick: closeSubDrawer }}
            >
              <DrawerItems>
                {!onSelect && (
                  <Checkbox
                    onChangeHook={() => {
                      goToPageWithSaveScrollPosition(
                        `/${selectedCategory?.path}` ?? '',
                        true
                      );
                    }}
                    label={t('catalogue.all_ads')}
                    appearance="radio"
                    size="sm"
                    hasSwitch={hasSwitchCheckbox}
                  />
                )}

                {selectedCategory?.children.length
                  ? selectedCategory.children.map(renderCategoryCheckbox)
                  : selectedCategory?.propertyOptions?.map(
                      renderPropertyCheckbox
                    )}
              </DrawerItems>
            </Drawer>
          </>
        )}
      </Drawer>
    );
  }
);

CatalogueDrawer.displayName = 'CatalogueDrawer';
