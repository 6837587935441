import React from 'react';
import * as S from './CatalogueListItem.styles';
import { ImageSkeletonLoader } from './CatalogueListItem.styles';
import { BaseCategory } from '@/api/types/categories.types';
import {
  ALL_CATEGORIES_ID,
  ALL_SHOPS_ID,
} from '@/components/CatalogueScrollGrid/CatalogueScrollGrid.constants';
import { getImageSide } from '@/components/CatalogueListItem/CatalogueListItem.helpers';

interface CatalogueListItemProps {
  item: BaseCategory;
  onClick?: () => void;
  isHorizontalView?: boolean;
  isStoresIcon?: boolean;
  isLoading?: boolean;
}

export const CatalogueListItem: React.FC<CatalogueListItemProps> = ({
  item,
  onClick,
  isHorizontalView,
  isStoresIcon,
  isLoading = false,
  ...props
}) => {
  const isCatalogueIcon = item.id === ALL_CATEGORIES_ID;
  const isDynamicCategory =
    item.id !== ALL_CATEGORIES_ID && item.id !== ALL_SHOPS_ID;

  return (
    <>
      <S.Box
        {...props}
        data-testid="catalogue-list-item"
        onClick={onClick}
        $isHorizontalView={isHorizontalView}
        $isLoading={isLoading}
        suppressHydrationWarning
      >
        <ImageSkeletonLoader $isHorizontalView={isHorizontalView} />
        <S.ImageBox $isHorizontalView={isHorizontalView}>
          {/* eslint-disable-next-line @next/next/no-img-element */}
          <img
            src={isDynamicCategory ? item.icon : `/images/${item.icon}`}
            alt={item.name}
            width={getImageSide(isCatalogueIcon, isStoresIcon)}
            height={getImageSide(isCatalogueIcon, isStoresIcon)}
            suppressHydrationWarning
          />
        </S.ImageBox>

        <S.Texts>
          {isHorizontalView ? (
            <S.GridItemTitle $variant="3" suppressHydrationWarning>
              {item.name}
            </S.GridItemTitle>
          ) : (
            <S.ItemTitle $variant="1">{item.name}</S.ItemTitle>
          )}

          {!isHorizontalView && (
            <S.ItemDescription>{item.metaDescription}</S.ItemDescription>
          )}
        </S.Texts>
      </S.Box>
    </>
  );
};
